import React, { useState, useEffect } from "react";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { formatDate } from "../../../../utils/dateFormat";

function JobPreferences({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [jobPreferences, setJobPreferences] = useState("");

  const fetchUserDetails = async () => {
    try {
      const param = {
        user_id: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-user-job-preferences");

      if (apiData.status) {
        setJobPreferences(apiData.data);
      } //else navigate("/admin/add-user-step2");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Profession :</h4>
          <p className="mb-3">{jobPreferences.profession || "-"}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Specialty :</h4>
          <p className="mb-3">{jobPreferences.specialty || "-"}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Available Start Date :</h4>
          <p className="mb-3">{jobPreferences.available_start_date || "-"}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Employment Type :</h4>
          <p className="mb-3">
            {jobPreferences.employement_types && jobPreferences.employement_types.length > 0
              ? jobPreferences.employement_types.join(", ")
              : "-"}
          </p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Shift :</h4>
          <p className="mb-3">
            {jobPreferences.shifts && jobPreferences.shifts.length > 0
              ? jobPreferences.shifts.join(", ")
              : "-"}
          </p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Desired States :</h4>
          <p className="mb-3">{jobPreferences.desired_state_ids || "-"}</p>
        </div>
      </div>
    </>
  );
}

export default JobPreferences;
