import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as document from "../../../services/DocumentService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { formatDate } from "../../../utils/dateFormat";
import PersonalInfo from "./Tabs/PersonalInfo";
import JobPreferences from "./Tabs/JobPreferences";
import EducationalInfo from "./Tabs/EducationalInfo";
import ContactInfo from "./Tabs/ContactInfo";
import Documents from "./Tabs/Documents";
import ComplianceFiles from "./Tabs/ComplianceFiles";
import SharedDocuments from "./Tabs/SharedDocuments";
import ProfessionalInfo from "./Tabs/ProfessionalInfo";
import Reference from "./Tabs/Reference";
/*
import EducationalInfo from "./Tabs/EducationalInfo";
*/
import Checklists from "./Tabs/Checklists";
import JobApplications from "./Tabs/JobApplications";
import Connections from "./Tabs/Connections";
import SMS from "./Tabs/SMS";
import Messages from "./Tabs/Messages";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import useSendRequest from "../../../hooks/useSendRequest";

function ClientUserProfile() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const { userUniqueID } = useParams();
  let navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("contact_info");
  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const [profileInfo, setProfileInfo] = useState("");

  const fetchUserDetails = async () => {
    try {
      const param = {
        step: "step1",
        user_id: user.id,
        token: user.token,
        userID: userUniqueID,
      };
      const apiData = await api.postMethod(param, "client/get-user-details");

      if (apiData.status) {
        setProfileInfo(apiData.data);

        setMessageFormData((prevState) => ({
          ...prevState,
          to_id: apiData.data.id,
        }));
      } //else navigate("/admin/add-user-step2");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userUniqueID]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageFormData, setMessageFormData] = useState({
    id: null,
    from_id: user.id,
    to_id: profileInfo.id ? profileInfo.id : 0,
    type: "email",
    message: "",
  });
  const toggleMessageModal = () => {
    setShowMessageModal(!showMessageModal);

    if (!showMessageModal) {
      setMessageFormData({
        id: null,
        from_id: user.id,
        to_id: profileInfo.id ? profileInfo.id : 0,
        type: "email",
        message: "",
      });

      // Reset validation errors
      setErrors({});
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    message: Yup.string().required("Message is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(messageFormData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(messageFormData, "client/send-message");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        setShowMessageModal(false);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setMessageFormData((prevState) => ({
      ...prevState,
      message: data,
    }));

    validateFields("message", data);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* SMS Modal Start */
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [isSMSLoading, setIsSMSLoading] = useState(false);
  const [smsFormData, setSMSFormData] = useState({
    id: null,
    from_id: user.id,
    to_id: 0,
    type: "sms",
    message: "",
  });

  useEffect(() => {
    if (profileInfo.id) {
      setSMSFormData((prevState) => ({
        ...prevState,
        to_id: profileInfo.id,
      }));
    }
  }, [profileInfo]);

  const toggleSMSModal = () => {
    setShowSMSModal(!showSMSModal);

    if (!showSMSModal) {
      setSMSFormData({
        id: null,
        from_id: user.id,
        /*to_id: profileInfo.id ? profileInfo.id : 0,*/
        type: "sms",
        message: "",
      });

      // Reset validation errors
      setSMSErrors({});
    }
  };

  //Define the validation schema
  const validationSMSSchema = Yup.object({
    message: Yup.string().required("Message is Required"),
  });

  const [smsErrors, setSMSErrors] = useState({});
  const handleSMSSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSMSSchema.validate(smsFormData, { abortEarly: false });

      setIsSMSLoading(true);
      const result = await api.postFileMethod(smsFormData, "client/send-message");
      setIsSMSLoading(false);
      if (result.status) {
        toast.success(result.message);
        setShowSMSModal(false);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsSMSLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setSMSErrors(newErrors);
    }
  };

  const handleSMSChange = (e) => {
    const { name, value } = e.target;
    setSMSFormData({ ...smsFormData, [name]: value });

    validateSMSFields(name, value);
  };

  const validateSMSFields = (name, value) => {
    try {
      validationSMSSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setSMSErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setSMSErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };
  /* SMS Modal End */

  const { request } = useSendRequest();
  const sendDocumentAccessReuqest = async (record) => {
    const confirmed = await request();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "client/send-document-access-request");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchUserDetails();
      }
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "User Profile", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="User Profile" breadcrumbItems={breadcrumbItems} />
              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="d-flex align-items-start mb-3">
                            {profileInfo.profile_pic_path && (
                              <img
                                src={profileInfo.profile_pic_path}
                                className="d-flex me-3 rounded-circle avatar-lg"
                                alt={profileInfo.name}
                                height={32}
                              />
                            )}
                            <div className="w-100">
                              <h4 className="mt-0 mb-1">
                                {profileInfo.name} (#{profileInfo.unique_id})
                              </h4>

                              {profileInfo.created_at && (
                                <p className="text-muted">
                                  <span className="text-dark fw-bold">Member Since</span> :{" "}
                                  {formatDate(profileInfo.created_at, "DD MMM YYYY")}
                                </p>
                              )}
                              <h4>
                                {profileInfo.status == 0 && (
                                  <label className="badge badge-outline-warning badge-pill">
                                    In-Active
                                  </label>
                                )}
                                {profileInfo.status == 1 && (
                                  <label className="badge badge-outline-success badge-pill">
                                    Active
                                  </label>
                                )}
                                {profileInfo.status == 2 && (
                                  <label className="badge badge-outline-danger badge-pill">
                                    Blocked
                                  </label>
                                )}
                                {profileInfo.status == 3 && (
                                  <label className="badge badge-outline-info badge-pill">
                                    Terminate
                                  </label>
                                )}
                              </h4>
                              <div className="w-100">
                                <div className="text-sm-start">
                                  {profileInfo.resume_path && (
                                    <a
                                      href={profileInfo.resume_path}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-primary waves-effect waves-light btn-sm me-1"
                                      data-tooltip-id="mt-4"
                                      data-tooltip-content="Download Document"
                                    >
                                      <i className="mdi mdi-download me-1" />
                                      <Tooltip id="mt-4"></Tooltip>
                                      Resume
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="text-sm-end">
                            {profileInfo.created_by == user.id ? (
                              <>
                                <Link
                                  to={
                                    `/client/add-${
                                      profileInfo.role_id == 9 ? "employee" : "candidate"
                                    }-step1/` + profileInfo.unique_id
                                  }
                                  className="btn btn-primary waves-effect waves-light mb-2 btn-sm me-1"
                                >
                                  <i className="fas fa-edit" /> Edit Profile
                                </Link>

                                {/* 
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect waves-light mb-2 btn-sm me-1"
                                  onClick={() => setShowSMSModal(true)}
                                >
                                  <i className="fas fa-mobile" /> SMS
                                </button>
                                */}
                              </>
                            ) : (
                              <>
                                {profileInfo.document_access_request_status == 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-info waves-effect waves-light mb-2 btn-sm me-1"
                                    disabled
                                    onClick={() => sendDocumentAccessReuqest(profileInfo)}
                                  >
                                    <i className="fas fa-paper-plane" /> Document Access Request
                                    Already Sent
                                  </button>
                                ) : (
                                  (profileInfo.document_access_request_status == "no_request" ||
                                    profileInfo.document_access_request_status == 2) && (
                                    <button
                                      type="button"
                                      className="btn btn-info waves-effect waves-light mb-2 btn-sm me-1"
                                      onClick={() => sendDocumentAccessReuqest(profileInfo)}
                                    >
                                      <i className="fas fa-paper-plane" /> Send Document Access
                                      Request
                                    </button>
                                  )
                                )}
                              </>
                            )}

                            {/*  
                            
                            <button
                              type="button"
                              className="btn btn-info waves-effect waves-light mb-2 btn-sm me-1"
                              onClick={() => setShowMessageModal(true)}
                            >
                              <i className="fas fa-envelope" /> Message
                            </button>
                            */}
                          </div>
                        </div>
                      </div>

                      <div className="row row-cols-5 mb-1">
                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "contact_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("contact_info")}
                          >
                            Contact Info
                          </button>
                        </div>

                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_preferences" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_preferences")}
                          >
                            Job Preferences
                          </button>
                        </div>

                        {/* 
                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "personal_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("personal_info")}
                          >
                            Personal Info
                          </button>
                        </div>
                        */}

                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "professional_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("professional_info")}
                          >
                            Work Histories
                          </button>
                        </div>

                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "educational_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("educational_info")}
                          >
                            Educational Info
                          </button>
                        </div>

                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "references" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("references")}
                          >
                            References
                          </button>
                        </div>

                        {profileInfo.created_by != user.id && (
                          <div className="col mb-2">
                            <button
                              type="button"
                              className={
                                "btn  w-100 " +
                                (selectedTab === "job_applications" ? "btn-primary" : "btn-light")
                              }
                              onClick={() => handleTabSelect("job_applications")}
                            >
                              Job Applications
                            </button>
                          </div>
                        )}

                        {/* 
                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "connections" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("connections")}
                          >
                            Connections
                          </button>
                        </div>
                        */}

                        {profileInfo.created_by != user.id && (
                          <div className="col mb-2">
                            <button
                              type="button"
                              className={
                                "btn  w-100 " +
                                (selectedTab === "checklists" ? "btn-primary" : "btn-light")
                              }
                              onClick={() => handleTabSelect("checklists")}
                            >
                              Skill Checklists
                            </button>
                          </div>
                        )}

                        {profileInfo.created_by == user.id && (
                          <>
                            {/* 
                            <div className="col mb-2">
                              <button
                                type="button"
                                className={
                                  "btn  w-100 " +
                                  (selectedTab === "sms" ? "btn-primary" : "btn-light")
                                }
                                onClick={() => handleTabSelect("sms")}
                              >
                                SMS
                              </button>
                            </div>
                            */}
                          </>
                        )}

                        {/*
                        <div className="col mb-2">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "messages" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("messages")}
                          >
                            Messages
                          </button>
                        </div>*/}

                        {/*  ||
                        profileInfo.created_by == user.id */}

                        {profileInfo.created_by != user.id &&
                          profileInfo.document_access_request_status == "1" && (
                            <>
                              <div className="col mb-2">
                                <button
                                  type="button"
                                  className={
                                    "btn  w-100 " +
                                    (selectedTab === "documents" ? "btn-primary" : "btn-light")
                                  }
                                  onClick={() => handleTabSelect("documents")}
                                >
                                  Documents
                                </button>
                              </div>
                            </>
                          )}

                        {/*
                        <div className="col mb-2">
                                <button
                                  type="button"
                                  className={
                                    "btn  w-100 " +
                                    (selectedTab === "shared-documents"
                                      ? "btn-primary"
                                      : "btn-light")
                                  }
                                  onClick={() => handleTabSelect("shared-documents")}
                                >
                                  Shared Documents
                                </button>
                              </div>
                        */}

                        {profileInfo.created_by == user.id && (
                          <>
                            <div className="col mb-2">
                              <button
                                type="button"
                                className={
                                  "btn  w-100 " +
                                  (selectedTab === "compliance-files" ? "btn-primary" : "btn-light")
                                }
                                onClick={() => handleTabSelect("compliance-files")}
                              >
                                Compliance Files
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      {selectedTab === "personal_info" && (
                        <PersonalInfo profileInfo={profileInfo} />
                      )}

                      {selectedTab === "contact_info" && <ContactInfo profileInfo={profileInfo} />}

                      {selectedTab === "professional_info" && (
                        <ProfessionalInfo profileInfo={profileInfo} />
                      )}

                      {selectedTab === "educational_info" && (
                        <EducationalInfo profileInfo={profileInfo} />
                      )}

                      {selectedTab === "job_preferences" && (
                        <JobPreferences profileInfo={profileInfo} />
                      )}

                      {selectedTab === "job_applications" && (
                        <JobApplications profileInfo={profileInfo} />
                      )}

                      {selectedTab === "connections" && <Connections profileInfo={profileInfo} />}

                      {selectedTab === "sms" && <SMS profileInfo={profileInfo} />}

                      {selectedTab === "messages" && <Messages profileInfo={profileInfo} />}

                      {selectedTab === "checklists" && <Checklists profileInfo={profileInfo} />}

                      {selectedTab === "documents" && <Documents profileInfo={profileInfo} />}

                      {selectedTab === "references" && <Reference profileInfo={profileInfo} />}

                      {selectedTab === "shared-documents" && (
                        <SharedDocuments profileInfo={profileInfo} />
                      )}

                      {selectedTab === "compliance-files" && (
                        <ComplianceFiles profileInfo={profileInfo} />
                      )}
                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <Modal
        show={showMessageModal}
        onHide={toggleMessageModal}
        centered
        backdrop="static"
        size="lg"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Send Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="mb-0 col-md-12">
                <label className="form-label" htmlFor="message">
                  Message *
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={handleChange}
                  name="message"
                  id="message"
                  data={messageFormData.message}
                />
                {errors.message && <span className="error">{errors.message}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleMessageModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showSMSModal} onHide={toggleSMSModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSMSSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Send SMS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="mb-0 col-md-12">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="form-control"
                  onChange={handleSMSChange}
                  name="message"
                  id="message"
                  value={smsFormData.message}
                  rows={5}
                />
                {smsErrors.message && <span className="error">{smsErrors.message}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleSMSModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
      <ThemeSettings />
    </>
  );
}

export default ClientUserProfile;
