import React, { useState, useEffect } from "react";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";

function SharedDocuments({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchDocuments = async () => {
    try {
      const param = {
        user_id: user.id,
        userID: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-user-shared-documents");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      setDocuments(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>Shared To</th>
              <th>Shared On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="5">No records found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(documents).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.title}</td>
                        <td>{value.doc_type_name}</td>
                        <th>{value.share_to}</th>
                        <td>
                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.created_at, "hh:mm A")}
                          </small>
                        </td>

                        <td>
                          <a
                            href={value.dir_path}
                            download
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-default action-icon"
                            data-tooltip-id="mt-4"
                            data-tooltip-content="Download Document"
                          >
                            <i className="mdi mdi-download" />
                          </a>
                          <Tooltip id="mt-4"></Tooltip>

                          {/*
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-1"
                                            data-tooltip-content="Share Document"
                                            onClick={() => handleShareHistory(value)}
                                          >
                                            <i className="mdi mdi-share" />
                                          </button>
                                          <Tooltip id="mt-1"></Tooltip>

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Document"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Document"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                           */}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SharedDocuments;
