import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { formatDate } from "../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreateTaskModal from "./CreateTaskModal";
import { currentRole } from "../../Layout/HelmetComponent";
import useConfirm from "../../../hooks/useConfirm";

const Tasks = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      getSchedules();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const getSchedules = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        tab: tab,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-tasks");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSchedules();
  }, []);
  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "client/delete-task");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        getSchedules();
      }
    }
  };

  const [editData, setEditData] = useState(null);
  const handleEdit = (record) => {
    setEditData({
      id: record.id,
      user_id: user.id,
      title: record.title,
      due_date: record.due_date,
      assignedUserIDs: record.user_ids ? record.user_ids.split(",").map(Number) : [],
      description: record.description,
    });

    setAddModalOpen(true);
  };

  const [uploadAddOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = () => {
    if (uploadAddOpen) {
      getSchedules();
    }
    setAddModalOpen(!uploadAddOpen);
  };

  // Empty dependency array ensures that the effect runs only once, when the component mounts

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = complianceFiles.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange2 = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(param, "client/task-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          getSchedules();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const updateFieldValue = async (id, field, value) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: value,
      };
      const result = await api.postMethod(param, "client/update-task-status");
      // Update the state with the new data
      setComplianceFiles(
        complianceFiles.map((record) => (record.id === id ? { ...record, [field]: value } : record))
      );

      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Tasks", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Tasks" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>
                      <option value="change-status-pending">Change Status to Pending</option>
                      <option value="change-status-in-progress">
                        Change Status to In-Progress
                      </option>
                      <option value="change-status-completed">Change Status to Completed</option>
                      <option value="delete">Delete</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Apply Action
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value={0}>Pending</option>
                            <option value={1}>In-Progress</option>
                            <option value={2}>Completed</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-right">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleAddModal}
                    >
                      <i className="fa fa-plus mr-1" /> Create New Task
                    </button>
                  </div>
                </div>
                {/* end col*/}
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === complianceFiles.length &&
                            complianceFiles.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>
                      <th>Task</th>
                      <th>Due Date</th>
                      <th>Assign To</th>
                      {/* <th>Status</th>*/}
                      <th>Created On</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="7">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td className="form-checks">
                                  <input
                                    type="checkbox"
                                    value={value.id}
                                    name="user_ids"
                                    onChange={handleCheckboxChange2}
                                    checked={selectedUserIds.includes(value.id)}
                                    className="form-check-input"
                                  />
                                </td>
                                <td>{value.title}</td>
                                <td>{formatDate(value.due_date, "MMM DD, YYYY")}</td>

                                <td>{value.user_names || "-"}</td>
                                {/* 
                                <td>
                                  {value.status == 0 && (
                                    <label className="badge badge-soft-warning">Pending</label>
                                  )}
                                  {value.status == 1 && (
                                    <label className="badge badge-soft-info">In-Progress</label>
                                  )}
                                  {value.status == 2 && (
                                    <label className="badge badge-soft-success">Completed</label>
                                  )}
                                </td>
                                */}
                                <td>
                                  {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </small>
                                </td>
                                <td>
                                  <select
                                    className="form-select my-1 my-lg-0"
                                    onChange={(e) =>
                                      updateFieldValue(value.id, "status", e.target.value)
                                    }
                                    value={value.status}
                                  >
                                    <option value="0">Pending</option>
                                    <option value="1">In-Progress</option>
                                    <option value="2">Completed</option>
                                  </select>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Edit Task"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-3"></Tooltip>

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Remove Task"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateTaskModal
        editData={editData}
        uploadAddOpen={uploadAddOpen}
        toggleAddModal={toggleAddModal}
      />

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default Tasks;
