import React, { useState, useRef, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "../Layout/Sidebar";
import ThemeSettings from "../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import * as tokenUtils from "../../utils/tokenUtils";
import Spinner from "../Loader/Spinner";
import * as api from "../../services/ApiService";
import { formatDate } from "../../utils/dateFormat";
import PageTitle from "../Layout/PageTitle";
import { currentRole } from "../Layout/HelmetComponent";
import { Tooltip } from "react-tooltip";

function Messages() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const messageTitlePreviewStyle = {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  };

  const messagePreviewStyle = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  };

  const [filters, setFilters] = useState({
    keyword: "",
  });

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const handleClearInput = () => {
    setFilters({ ...filters, keyword: "" });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobData = async () => {
    try {
      setLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
        client_id: user.client_id,
        keyword: filters.keyword,
      };
      const apiData = await api.postMethod(param, "client/get-unique-job-messages");

      setMessages(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, [filters.keyword]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [selectedJob, setSelectedJob] = useState("");

  const messagesEndRef = useRef(null);

  const [jobMessages, setJobMessages] = useState({});
  const [jobMessageLoading, setJobMessageLoading] = useState(false);
  const [noJobMessageRecordsFound, setNoJobMessageRecordsFound] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [jobMessages]);

  /* Job Message Submission */
  const [formData, setformData] = useState({
    id: null,
    from_id: user.id,
    user_id: user.id,
    client_id: user.client_id,
    to_id: null,
    token: user.token,
    job_id: null,
    message: "",
  });

  const fetchJobMessage = async (record) => {
    setJobMessageLoading(true);
    setJobMessages({});
    setErrors({});

    setSelectedJob(record.job_title);

    setformData((prevFormData) => ({
      ...prevFormData,
      job_id: record.job_id,
      to_id: record.user_id,
      user_id: record.user_id,
      job_chat_id: record.job_chat_id,
    }));

    /*setIsLoading(true);*/

    try {
      const param = {
        user_id: user.id,
        job_id: record.job_id,
        userID: record.user_id,
        job_chat_id: record.job_chat_id,
      };

      const apiData = await api.postMethod(param, "client/get-job-messages");

      setJobMessages(apiData.data);
      // Check if data array is empty
      setNoJobMessageRecordsFound(false);
      if (apiData.data.length === 0) {
        setNoJobMessageRecordsFound(true);
      }
      setIsLoading(false);
      setJobMessageLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      setJobMessageLoading(false);
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    message: Yup.string().required("Message is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      /*
      setformData((prevFormData) => ({
        ...prevFormData,
        user_id: user.id,
      }));
      */

      const result = await api.postMethod(formData, "client/insert-job-message");
      if (result.status) {
        setformData((prevFormData) => ({
          ...prevFormData,
          message: "",
        }));

        /*
        setformData((prevFormData) => ({
          ...prevFormData,
          to_id: formData.to_id,
          user_id: formData.user_id,
        }));
        */

        fetchJobData();
        fetchJobMessage(formData);
        setIsLoading(false);
        toast.success("Message has been sent!");
      } else {
        toast.error(result.message);
        setIsLoading(false);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Messages", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Messages" breadcrumbItems={breadcrumbItems} />

              <div className="row">
                <div className="col-12">
                  <div className="card" style={{ height: "700px" }}>
                    <div className="card-body h-100">
                      {/* Left sidebar */}
                      <div
                        className="inbox-leftbar ps-0 h-100"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <form
                          className="search-bar mb-3"
                          style={{
                            flex: "0 0 auto",
                          }}
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control form-control-light"
                              placeholder="Search..."
                              onChange={handleFilterInputChange}
                              name="keyword"
                              value={filters.keyword}
                            />
                            <span className="mdi mdi-magnify" />
                            {filters.keyword && (
                              <button
                                className="btn btn-link position-absolute p-0"
                                style={{ right: "10px", top: "50%", transform: "translateY(-50%)" }}
                                onClick={() => handleClearInput()}
                              >
                                <span
                                  className="mdi mdi-close position-relative"
                                  style={{ left: "0px", top: "0px" }}
                                />
                              </button>
                            )}
                          </div>
                        </form>
                        <div
                          style={{
                            flex: "1 1 auto",
                            overflowY: "auto",
                          }}
                        >
                          {loading ? (
                            <p className="text-center">Loading...</p>
                          ) : (
                            <>
                              {noRecordsFound ? (
                                <p className="text-center">No message available</p>
                              ) : (
                                <>
                                  {Object.entries(messages).map(([key, value]) => (
                                    <div
                                      className="text-body cursor-pointer"
                                      key={value.user_id}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => fetchJobMessage(value)}
                                    >
                                      <div className="d-flex align-items-start py-2">
                                        {value.profile_pic_path ? (
                                          <img
                                            src={value.profile_pic_path}
                                            className="me-2 rounded-circle border"
                                            height={32}
                                            width={32}
                                            alt={value.name}
                                          />
                                        ) : (
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/users/default-user.png"
                                            }
                                            className="me-2 rounded-circle border"
                                            height={32}
                                            width={32}
                                            alt={value.name}
                                          />
                                        )}
                                        <div className="w-100">
                                          <h5
                                            className="mt-0 mb-0 font-14 text-info"
                                            style={messageTitlePreviewStyle}
                                            data-tooltip-id={`mt-` + value.user_id}
                                            data-tooltip-content={value.name}
                                          >
                                            <span className="float-end text-muted fw-normal font-12">
                                              {formatDate(value.created_at, "date_time_difference")}
                                            </span>
                                            {value.name}
                                          </h5>
                                          <Tooltip id={`mt-` + value.user_id}></Tooltip>

                                          <h6
                                            className="mt-1 mb-0 text-black-50 fw-semibold"
                                            style={messageTitlePreviewStyle}
                                            data-tooltip-id={`mst-` + value.user_id}
                                            data-tooltip-content={value.job_title}
                                          >
                                            {value.job_title}
                                          </h6>
                                          <Tooltip id={`mst-` + value.user_id}></Tooltip>

                                          <p className="mt-1 mb-0 text-muted font-14">
                                            <span className="w-100" style={messagePreviewStyle}>
                                              {value.message}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {/* end slimscroll*/}
                      </div>
                      {/* End Left sidebar */}
                      <div className="inbox-rightbar h-100">
                        {jobMessageLoading ? (
                          <div className="message-body d-flex justify-content-center align-items-center h-100 ">
                            <h3 className="text-center">Loading messages ...</h3>
                          </div>
                        ) : (
                          <>
                            {noJobMessageRecordsFound ? (
                              <>
                                {messages.length > 0 ? (
                                  <div class="initial-message message-body d-flex justify-content-center align-items-center h-100">
                                    <div className="text-center">
                                      <h4 className="mb-3">Manage Messages for Your Jobs</h4>
                                      <p>
                                        Send messages to new applicants by visiting your{" "}
                                        <Link to="/client/job-applications">Job Applications</Link>{" "}
                                        and selecting Actions.
                                      </p>
                                      <p>
                                        Once you select Actions, click on Send Message to start
                                        messaging applicants.
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div class="initial-message message-body d-flex justify-content-center align-items-center h-100">
                                    <div className="text-center">
                                      <h4 className="mb-3">Welcome to the Messaging System!</h4>
                                      <p>
                                        It looks like you don't have any job-related messages yet.
                                      </p>
                                      <p>
                                        To start receiving messages from applicants, please visit
                                        the <Link to="/client/job-postings">Job Posting Page</Link>{" "}
                                        and create a job listing. Once applicants send messages to
                                        your job posts, you'll see them listed here.
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  className="message-container-main"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "660px",
                                  }}
                                >
                                  <div
                                    className="mcmain-header border-bottom pb-3 mb-2"
                                    style={{
                                      flex: "0 0 auto",
                                    }}
                                  >
                                    <h5 className="font-18 m-0">{selectedJob}</h5>
                                  </div>
                                  <div
                                    className="mcmain-body pe-2"
                                    style={{
                                      flex: "1 1 auto",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {Object.entries(jobMessages).map(([key, value]) => (
                                      <>
                                        {value.receiver_user_id == user.id ? (
                                          <div className="single-message mb-3" key={value.id}>
                                            <div className="d-flex align-items-start mb-0 mt-0">
                                              {value.sender_profile_pic_path ? (
                                                <img
                                                  src={value.sender_profile_pic_path}
                                                  className="d-flex me-2 rounded-circle border"
                                                  height={32}
                                                  alt={value.sender_name}
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/users/default-user.png"
                                                  }
                                                  className="d-flex me-2 rounded-circle border"
                                                  height={32}
                                                  alt={value.sender_name}
                                                />
                                              )}
                                              <div className="w-100">
                                                <h6 className="m-0 font-14">{value.sender_name}</h6>
                                                <small className="text-muted">
                                                  {formatDate(value.created_at, "DD MMM YYYY")}{" "}
                                                  {formatDate(value.created_at, "hh:mm A")}
                                                </small>
                                                <div className="message-body mt-2">
                                                  {value.message}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="single-message mb-3" key={value.id}>
                                            <div className="d-flex align-items-start mb-0 mt-0">
                                              <div className="w-100 text-end">
                                                <h6 className="m-0 font-14">{value.sender_name}</h6>
                                                <small className="text-muted">
                                                  {formatDate(value.created_at, "DD MMM YYYY")}{" "}
                                                  {formatDate(value.created_at, "hh:mm A")}
                                                </small>
                                                <div className="message-body text-end  mt-2">
                                                  {value.message}
                                                </div>
                                              </div>
                                              {value.sender_profile_pic_path ? (
                                                <img
                                                  src={value.sender_profile_pic_path}
                                                  className="d-flex ms-2 rounded-circle border"
                                                  height={32}
                                                  alt={value.sender_name}
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/users/default-user.png"
                                                  }
                                                  className="d-flex ms-2 rounded-circle border"
                                                  height={32}
                                                  alt={value.sender_name}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ))}
                                    <div ref={messagesEndRef} />
                                  </div>
                                  <div
                                    className="mcmain-footer border-top pt-2 mt-2"
                                    style={{
                                      flex: "0 0 auto",
                                    }}
                                  >
                                    <form onSubmit={handleSubmit}>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="message"
                                          onChange={handleChange}
                                          defaultValue={formData.message}
                                        />
                                        <button type="submit" className="btn btn-primary">
                                          Submit
                                        </button>
                                      </div>
                                      {errors.message && (
                                        <span className="error">{errors.message}</span>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* end card-box */}
                </div>
                {/* end col */}
              </div>
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Messages;
