import React, { useState, useEffect, useRef } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import useConfirm from "../../../hooks/useConfirm";
import AssignmentModal from "./AssignmentModal";

function ManageAssignments() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const resetFormDataAndErrorsRef = useRef(null);

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        user_role_id: user.role_id,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(
        queryParams,
        "client/get-assignments"
      );

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setViewOnly(false);
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen && resetFormDataAndErrorsRef.current) {
      resetFormDataAndErrorsRef.current(); // Call the reset function from the child component
    }
  };

  const [editAssignmentData, setEditAssignmentData] = useState({});

  const [viewOnly, setViewOnly] = useState(false);

  const handleEdit = (record) => {
    setViewOnly(false);
    // Populate formData with the values of the selected record
    setEditAssignmentData({
      user_id: user.id,
      id: record.id,
      assigned_user_id: record.user_id,
      facility_id: record.facility_id,
      state_id: record.state_id,
      city_id: record.city_id,
      assigned_unit: record.assigned_unit,
      start_date: record.start_date,
      end_date: record.end_date,
      assignment_length: record.assignment_length,
      shift: record.shift,
      approved_time_off: record.approved_time_off,
      confirmed_with_facility: record.confirmed_with_facility,
      confirmed_with_traveler: record.confirmed_with_traveler,
      status: record.status,
    });

    setUploadModalOpen(true);
  };

  const handleView = (record) => {
    setViewOnly(true);
    // Populate formData with the values of the selected record
    setEditAssignmentData({
      user_id: user.id,
      id: record.id,
      assigned_user_id: record.user_id,
      facility_id: record.facility_id,
      state_id: record.state_id,
      city_id: record.city_id,
      assigned_unit: record.assigned_unit,
      start_date: record.start_date,
      end_date: record.end_date,
      assignment_length: record.assignment_length,
      shift: record.shift,
      approved_time_off: record.approved_time_off,
      confirmed_with_facility: record.confirmed_with_facility,
      confirmed_with_traveler: record.confirmed_with_traveler,
      status: record.status,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();

  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "client/delete-assignment");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(
        param,
        "client/update-assignment-status"
      );
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Assignments", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}

              <PageTitle
                title="Assignments"
                breadcrumbItems={breadcrumbItems}
              />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleFilterDropDown}
                            >
                              Filters
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div
                              className={`dropdown-menu ${
                                showFilterDropdown ? "show" : ""
                              }`}
                            >
                              <form
                                className="px-2 py-2"
                                onSubmit={handleFilterSubmit}
                              >
                                <div className="mb-2">
                                  <label
                                    htmlFor="keyword"
                                    className="form-label"
                                  >
                                    Keyword
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="keyword"
                                    name="keyword"
                                    value={filters.keyword}
                                    onChange={handleFilterInputChange}
                                  />
                                </div>

                                <div className="mb-2">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                  >
                                    Status
                                  </label>
                                  <select
                                    className="form-select"
                                    id="cstatus"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterInputChange}
                                  >
                                    <option value={"all"}>Please Select</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>In-Active</option>
                                  </select>
                                </div>

                                <div className="text-end mt-1">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset Filter
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                              onClick={toggleUploadModal}
                            >
                              <i className="fa fa-plus mr-1"></i> Add New
                            </button>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Facility Name</th>
                              <th>Assignee</th>
                              <th>Start/End Date</th>
                              <th>Location</th>
                              <th>Date Added</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="7">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="7">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(complianceFiles).map(
                                      ([key, value]) => (
                                        <tr key={value.id}>
                                          <td>{value.facility_title}</td>
                                          <td>
                                            <Link
                                              to={
                                                `/client/user-profile/` +
                                                value.user_unique_id
                                              }
                                              className=""
                                            >
                                              {value.name}
                                            </Link>
                                          </td>
                                          <td>
                                            {value.start_date &&
                                            value.end_date ? (
                                              <>
                                                {formatDate(
                                                  value.start_date,
                                                  "MMM DD, YYYY"
                                                )}{" "}
                                                to{" "}
                                                {formatDate(
                                                  value.end_date,
                                                  "MMM DD, YYYY"
                                                )}
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                          <td>
                                            {value.state_code !== null &&
                                            value.city_name !== null
                                              ? value.city_name +
                                                ", " +
                                                value.state_code
                                              : value.state_code !== null
                                              ? value.state_code
                                              : value.city_name !== null
                                              ? value.city_name
                                              : ""}
                                          </td>
                                          <td>
                                            {formatDate(
                                              value.created_at,
                                              "MMM DD, YYYY"
                                            )}{" "}
                                            <small className="text-muted">
                                              {formatDate(
                                                value.created_at,
                                                "hh:mm A"
                                              )}
                                            </small>
                                          </td>
                                          <td>
                                            {value.status == 1 && (
                                              <span className="badge badge-soft-success">
                                                Active
                                              </span>
                                            )}
                                            {value.status == 0 && (
                                              <span className="badge badge-soft-warning">
                                                In-Active
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {value.status == 0 && (
                                              <>
                                                <button
                                                  type="button"
                                                  className="btn btn-default action-icon"
                                                  data-tooltip-id="mt-1"
                                                  data-tooltip-content="In-Active"
                                                  onClick={() =>
                                                    updateStatus(value.id, 1)
                                                  }
                                                >
                                                  <i className="mdi mdi-close-box-outline" />
                                                </button>
                                                <Tooltip id="mt-1"></Tooltip>
                                              </>
                                            )}
                                            {value.status == 1 && (
                                              <>
                                                <button
                                                  type="button"
                                                  className="btn btn-default action-icon"
                                                  data-tooltip-id="mt-1"
                                                  data-tooltip-content="Active"
                                                  onClick={() =>
                                                    updateStatus(value.id, 0)
                                                  }
                                                >
                                                  <i className="mdi mdi-check-box-outline" />
                                                </button>
                                                <Tooltip id="mt-1"></Tooltip>
                                              </>
                                            )}

                                            <button
                                              type="button"
                                              className="btn btn-default action-icon"
                                              data-tooltip-id="mt-3"
                                              data-tooltip-content="Edit Assignment"
                                              onClick={() => handleEdit(value)}
                                            >
                                              <i className="mdi mdi-pencil" />
                                            </button>

                                            <button
                                              type="button"
                                              className="btn btn-default action-icon"
                                              data-tooltip-id="mt-3"
                                              data-tooltip-content="View Assignment"
                                              onClick={() => handleView(value)}
                                            >
                                              <i className="mdi mdi-eye-outline" />
                                            </button>
                                            <Tooltip id="mt-3"></Tooltip>
                                            <button
                                              type="button"
                                              className="btn btn-default action-icon"
                                              data-tooltip-id="mt-2"
                                              data-tooltip-content="Remove Assignment"
                                              onClick={() =>
                                                handleDelete(value)
                                              }
                                            >
                                              <i className="mdi mdi-delete" />
                                            </button>
                                            <Tooltip id="mt-2"></Tooltip>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <AssignmentModal
        editAssignmentData={editAssignmentData}
        uploadModalOpen={uploadModalOpen}
        toggleUploadModal={toggleUploadModal}
        setIsLoading={setIsLoading}
        fetchComplianceFiles={fetchComplianceFiles}
        resetFormDataAndErrorsRef={resetFormDataAndErrorsRef}
        viewOnly={viewOnly}
      />

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ManageAssignments;
