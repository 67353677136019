import React, { useState, useEffect } from "react";
import axios from "axios"; // Or any other library you use for making API calls
import ReferenceForm from "../Tabs/Components/ReferenceForm";
import * as Yup from "yup";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { phoneRegExp } from "../../../../utils/dateFormat";
import { toast } from "react-toastify";

const ReferencesPage = ({ profileInfo }) => {
  let user = tokenUtils.getTokenData();

  const initialReferences = [
    { title: "", name: "", email: "", phone: "" },
    { title: "", name: "", email: "", phone: "" },
    { title: "", name: "", email: "", phone: "" },
  ];

  const [data, setData] = useState({
    user_id: user.id,
    token: user.token,
    references: initialReferences,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from API when component mounts
    const fetchReferences = async () => {
      try {
        const param = {
          user_id: user.id,
          token: user.token,
          userID: profileInfo.id,
        };
        const apiData = await api.postMethod(param, "client/get-user-references");
        let references = apiData.data;
        // Ensure there are always three references
        if (references.length < 3) {
          references = [
            ...references,
            ...Array(3 - references.length).fill({ title: "", name: "", email: "", phone: "" }),
          ];
        }

        setData((prevData) => ({
          ...prevData,
          references,
        }));
      } catch (error) {
        console.error("Error fetching references:", error);
        setLoading(false);
      }
    };

    fetchReferences();
  }, []);

  return (
    <>
      {data.references.map((reference, index) => (
        <ReferenceForm key={index} index={index} reference={reference} />
      ))}
    </>
  );
};

export default ReferencesPage;
