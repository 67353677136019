import React, { useState, useEffect } from "react";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";

function ComplianceFiles({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    expiration_start_date: "",
    expiration_end_date: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      expiration_start_date: "",
      expiration_end_date: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        userID: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-user-compliance-files");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      setComplianceFiles(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>Expiration Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="5">No records found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(complianceFiles).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.title}</td>
                        <td>{value.doc_type_name}</td>
                        <td>{formatDate(value.expiration_date, "MMM DD, YYYY")}</td>
                        <td>
                          {value.status == 1 && (
                            <label className="badge badge-soft-success">Active</label>
                          )}
                          {value.status == 0 && (
                            <label className="badge badge-soft-warning">In-Active</label>
                          )}
                        </td>
                        <td>
                          {value.dir_path && (
                            <>
                              <Link
                                to={value.dir_path}
                                download
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-default action-icon"
                                data-tooltip-id="mt-4"
                                data-tooltip-content="Download Document"
                              >
                                <i className="mdi mdi-download" />
                              </Link>
                              <Tooltip id="mt-4"></Tooltip>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ComplianceFiles;
