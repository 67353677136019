import React, { useState, useEffect } from "react";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { formatDate } from "../../../../utils/dateFormat";

function PersonalInfo({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Date of Birth :</h4>
          <p className="mb-3">
            {profileInfo.dob ? formatDate(profileInfo.dob, "DD MMM YYYY") : "-"} {/* (34 Years)*/}
          </p>
        </div>
      </div>
    </>
  );
}

export default PersonalInfo;
