import React, { useState, useEffect, useRef } from "react";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";

function Checklists({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const printRef = useRef();

  const handlePrint = () => {
    /*
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    */
    //window.location.reload();

    const printContent = printRef.current.innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            ${Array.from(document.styleSheets)
              .map((styleSheet) => {
                try {
                  return Array.from(styleSheet.cssRules)
                    .map((rule) => rule.cssText)
                    .join("");
                } catch (e) {
                  return "";
                }
              })
              .join("")}
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.close();
  };

  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        userID: profileInfo.id,
      };
      const apiData = await api.postMethod(param, "client/get-user-submitted-checklists");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = (checklist) => {
    checklist.checklistMeta = JSON.parse(checklist.checklist_meta);
    checklist.userChecklistMeta = JSON.parse(checklist.user_checklist_meta);
    setSelectedChecklist(checklist);
    setShowModal(true);
  };

  // Render radio buttons
  const renderRadioButtons = (sectionIndex, skillIndex, type, options, selectedValues) => {
    return options.map((option, optionIndex) => (
      <label key={optionIndex} className="mx-1">
        <input
          type="radio"
          name={`${sectionIndex}-${skillIndex}-${type}`}
          value={option.value}
          className="me-1 disabled"
          checked={selectedValues[sectionIndex]?.[skillIndex]?.[type] === option.value}
          disabled
        />
        {option.title}
      </label>
    ));
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Checklist Title</th>
              <th>Created By</th>
              <th>Submitted On</th>
              <th>Updated On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="5">No records found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(complianceFiles).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.title}</td>
                        <td>
                          {(value.creator_role_id == 2 && 0 && (
                            <Link
                              to={`/admin/facility-profile/` + value.creator_unique_id}
                              className=""
                            >
                              {value.creator_name}
                            </Link>
                          )) ||
                            (value.creator_role_id == 3 && 0 && (
                              <Link
                                to={`/admin/agency-profile/` + value.creator_unique_id}
                                className=""
                              >
                                {value.creator_name}
                              </Link>
                            )) || <span className="">{value.creator_name}</span>}
                        </td>
                        <td>
                          {formatDate(value.submitted_on, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.submitted_on, "hh:mm A")}
                          </small>
                        </td>
                        <td>
                          {formatDate(value.updated_on, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.updated_on, "hh:mm A")}
                          </small>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-default action-icon"
                            data-tooltip-id="mt-2"
                            data-tooltip-content="View Result"
                            onClick={() => handleModalShow(value)}
                          >
                            <i className="mdi mdi-eye" />
                          </button>
                          <Tooltip id="mt-2"></Tooltip>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {selectedChecklist && (
        <Modal show={showModal} onHide={handleModalClose} centered backdrop="static" size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="m-0">{selectedChecklist.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body ref={printRef}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <p className="mb-0">
                    <b>Checklist Title</b>
                  </p>
                  <p className="text-muted">{selectedChecklist.title}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <p className="mb-0">
                    <b>Posted By</b>
                  </p>
                  <p className="text-muted">{selectedChecklist.creator_name}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <p className="mb-0">
                    <b>Submitted On</b>
                  </p>
                  <p className="text-muted">
                    {formatDate(selectedChecklist.submitted_on, "DD MMM YYYY")}{" "}
                    <small className="text-muted">
                      {formatDate(selectedChecklist.submitted_on, "hh:mm A")}
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <p className="mb-0">
                    <b>Updated On</b>
                  </p>
                  <p className="text-muted">
                    {formatDate(selectedChecklist.updated_on, "DD MMM YYYY")}{" "}
                    <small className="text-muted">
                      {formatDate(selectedChecklist.updated_on, "hh:mm A")}
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                {selectedChecklist.checklistMeta.length > 0 &&
                  selectedChecklist.checklistMeta.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="">
                      <h4 className="">{section.sectionTitle}</h4>

                      <table className="table table-bordered mb-0 table-sm">
                        <thead>
                          <tr>
                            <th style={{ width: "33%" }}> </th>
                            <th className="text-center" style={{ width: "33%" }}>
                              Proficiency
                            </th>
                            <th className="text-center" style={{ width: "33%" }}>
                              Frequency
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {section.skills.map((skill, skillIndex) => (
                            <tr className="mt-2" key={skillIndex}>
                              <th>
                                <p className="m-0">{skill.skillTitle}</p>
                              </th>
                              <td className="text-center">
                                {renderRadioButtons(
                                  section.sectionTitle,
                                  skill.skillTitle,
                                  "proficiency",
                                  skill.options.proficiency,
                                  selectedChecklist.userChecklistMeta
                                )}
                              </td>
                              <td className="text-center">
                                {renderRadioButtons(
                                  section.sectionTitle,
                                  skill.skillTitle,
                                  "frequency",
                                  skill.options.frequency,
                                  selectedChecklist.userChecklistMeta
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handlePrint} className="btn btn-info pull-start">
              Print
            </button>

            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Checklists;
