import React from "react";

const ReferenceForm = ({ index, reference, handleChange }) => {
  return (
    <div>
      <h5>Reference {index + 1}</h5>
      <div className="row">
        <div className="col-md-6 mb-2">
          <label className="form-label">Title</label>
          <input
            id="title"
            type="text"
            name="title"
            value={reference.title}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-2">
          <label className="form-label">Name</label>
          <input
            type="text"
            name="name"
            value={reference.name}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-2">
          <label className="form-label">Email</label>
          <input
            type="email"
            name="email"
            value={reference.email}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-2">
          <label className="form-label">Phone</label>
          <input
            type="tel"
            name="phone"
            value={reference.phone}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
};

export default ReferenceForm;
