import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Sidebar from '../Layout/Sidebar';
import ThemeSettings from '../Layout/ThemeSettings';
import { Link } from 'react-router-dom';
import Spinner from '../Loader/Spinner';
import * as tokenUtils from '../../utils/tokenUtils';
import * as api from '../../services/ApiService';

function ComplianceLists() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isLoading, setIsLoading] = useState(false);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [complianceChecklists, setComplianceChecklists] = useState([]);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceChecklists = async () => {
    setIsLoading(true);
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-compliances");

      // Debugging step: Log the response to ensure we're getting the expected data
      console.log('API Response:', apiData);

      if (apiData && apiData.data && Array.isArray(apiData.data) && apiData.data.length > 0) {
        setComplianceChecklists(apiData.data);
        setNoRecordsFound(false);
      } else {
        setComplianceChecklists([]); // Ensure the list is cleared
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setComplianceChecklists([]);
      setNoRecordsFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceChecklists();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  // Filtered compliance checklists based on the search keyword
  const filteredChecklists = complianceChecklists.filter((item) =>
    item.title.toLowerCase().includes(filterKeyword.toLowerCase())
  );

  // Update noRecordsFound based on filtered results or initial fetch
  useEffect(() => {
    if (isLoading) {
      setNoRecordsFound(false); // Loading state, no message
    } else if (filterKeyword.length > 0) {
      setNoRecordsFound(filteredChecklists.length === 0);
    } else {
      setNoRecordsFound(complianceChecklists.length === 0);
    }
  }, [filterKeyword, complianceChecklists, isLoading]);

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            <div className="container-fluid">
              {/* Start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Facility Compliance Lists</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Facility Compliance Lists</h4>
                  </div>
                </div>
              </div>
              {/* End page title */}

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      {/* Search Bar */}
                      <div className="row justify-content-between mb-3">
                        <div className="col-auto">
                          <form className="d-flex flex-wrap align-items-center">
                            <div className="me-3">
                              <label htmlFor="filterKeyword" className="sr-only">
                                Search
                              </label>
                              <input
                                type="search"
                                className="form-control"
                                id="filterKeyword"
                                placeholder="Search by keyword..."
                                onChange={(e) => setFilterKeyword(e.target.value)}
                                value={filterKeyword}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* End Search Bar */}

                      {/* Compliance Checklist Table */}
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Checklist Title</th>
                              <th>Assigned Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  Loading...
                                </td>
                              </tr>
                            ) : noRecordsFound ? (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              filteredChecklists.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.title}</td>
                                  <td>{formatDate(item.created_at)}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* End Compliance Checklist Table */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading && <Spinner />}
    </>
  );
}

export default ComplianceLists;
